.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1rem;

	width: 100%;
	height: 100vh;

	> button {
		flex-basis: 5%;
	}
}

.stepManager {
	flex: 1;

	width: 100%;
	height: 100%;

	padding: 1rem;
}

.spinnerContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.2);
}

.navbar {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: 1rem;

	justify-content: space-between;
}

.filterContainer {
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;

	padding: 1rem;
}
