:global {
	* {
		box-sizing: border-box;
	}

	html,
	body {
		margin: 0;
		width: 100%;
		height: 100%;
	}

	#container {
		width: 100%;
		height: 100%;
	}
}
